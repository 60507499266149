import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { useFlexSearch } from 'react-use-flexsearch';
import { Link } from 'gatsby';
import _ from 'lodash';
import HTMLReactParser from 'html-react-parser';
import $ from "jquery";

const LocalSiteSearch = (props) => {
    const queryData = useStaticQuery(graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `)
    const index = queryData.localSearchPages?.index
    const store = queryData.localSearchPages.store
    const [query, setQuery] = useState('')
    const results = useFlexSearch(query, index, store)

    return (
        <>
            <input
                name="query"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                className="form-control search-overlay-input"
                placeholder="Search the website"
            />
            {results.length > 0 ? (
                <div className="hit-feed py-3">
                    <ListItems items={results} query={query} />
                </div>
            ) : null}
        </>
    );
}

const valuesDeep = (input, initialValues = []) => {
    if (_.isObject(input))
        return [
            ...initialValues,
            ...Object.values(input).map((val) => valuesDeep(val, initialValues)),
        ].flat()

    if (_.isArray(input))
        return [
            ...initialValues,
            ...input.map((val) => valuesDeep(val, initialValues)),
        ].flat()

    return [...initialValues, input]
}

const highlightContent = (props) => {
    // const content = valuesDeep(props.content).join('<br>')
    // var regex = new RegExp(props.query, "g");
    return HTMLReactParser(`<h4>${_.capitalize(props.pageName)}</h4>`)
}

function ItemLinks(props) {
    // Close overlay
    const closeSearchOverlay = (e) => {
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }
    // Close overlay
    if (!_.isEmpty(props.item)) {
        const othermenu = props.menu && props.menu[0]
        const item = props.item;
        const i = props.iterator; 
        if(item.Secondary_URL != null) {
            return (
                <li role="option" tabIndex={i}><Link onClick={closeSearchOverlay} to={item.Secondary_URL}>{highlightContent(props)}</Link></li>
            ); 
        }else if (!_.isEmpty(item.Main_Parent) && _.isEmpty(item.Sub_Parent) && item.Main_Parent.Disable_Parent_URL != true && item.Secondary_URL === null) {
            return (
                <li role="option" tabIndex={i}><Link onClick={closeSearchOverlay} to={"/" + item.Main_Parent.Primary_URL + "/" + item.Primary_URL}>{highlightContent(props)}</Link></li>
            );
        } else if (!_.isEmpty(item.Main_Parent) && _.isEmpty(item.Sub_Parent) && item.Main_Parent.Disable_Parent_URL === true && item.Secondary_URL === null) {
            return (
                <li role="option" tabIndex={i}><Link onClick={closeSearchOverlay} to={"/" + item.Primary_URL}>{highlightContent(props)}</Link></li>
            );
        } else if (!_.isEmpty(item.Main_Parent) && !_.isEmpty(item.Sub_Parent) && item.Main_Parent.Disable_Parent_URL === true && item.Secondary_URL === null) { 
            return (
                <li role="option" tabIndex={i}><Link onClick={closeSearchOverlay} className="pl-1" to={"/" + item.Sub_Parent.Primary_URL + "/" + item.Primary_URL}>{highlightContent(props)}</Link></li>
            )
        }else if (!_.isEmpty(item.Main_Parent) && !_.isEmpty(item.Sub_Parent) && item.Main_Parent.Disable_Parent_URL != true && item.Secondary_URL === null) { 
            return (
                <li role="option" tabIndex={i}><Link onClick={closeSearchOverlay} className="pl-1" to={"/" + item.Main_Parent.Primary_URL + "/" + item.Sub_Parent.Primary_URL + "/" + item.Primary_URL}>{highlightContent(props)}</Link></li>
            )
        }
        else {

            let url = "/" + item.Primary_URL
            if (url == "/home") {
                url = "/";
            } 
            if(othermenu) {
                url = "/" + othermenu.URL
            }
         //console.log(item)
            return (
                <li role="option" tabIndex={i}><Link onClick={closeSearchOverlay} to={url}>{highlightContent(props)}</Link></li>
            );
        }
    } 
}

const ListItems = (props) => {
    const items = props.items;
    let iterator = 0;
    const listItems = items.map((item, k) => {
        iterator++;
        return (
            <ItemLinks key={k} pageName={item.title} iterator={iterator} content={item.content} item={item.content} menu={item.menu} type={item.type} query={props.query}  />
        )
    });
    return (
        <ul role="listbox">{listItems}</ul>
    );
}

export default LocalSiteSearch;
