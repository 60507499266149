import React,{useState, useEffect} from 'react'
import {
    Button,
    Form,
    Container,
    FormControl,
    Row,
    InputGroup,
    Col
  } from "react-bootstrap";
  import { Link } from "gatsby";
  import { useStaticQuery, graphql } from "gatsby"
  import GetCta from '../getcta'

const FooterMenu=(props)=>{
  const [OpenMenu1, setOpenMenu1] = useState(false);
  const [OpenMenu2, setOpenMenu2] = useState(false);
  const [OpenMenu3, setOpenMenu3] = useState(false);
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        Footer_Menu_1
        Footer_Menu_2
        Footer_Menu_3
        Footer_Menu_List_1 {
          Footer_Menu_Custom_Link
          Footer_Menu_Label
          Footer_Menu_Link {
            Primary_URL
          }
        }
        Footer_Menu_List_2 {
          Footer_Menu_Custom_Link
          Footer_Menu_Label
          Footer_Menu_Link {
            Primary_URL
          }
        }
        Footer_Menu_List_3 {
          Footer_Menu_Custom_Link
          Footer_Menu_Label
          Footer_Menu_Link {
            Primary_URL
          }
        }
      }
    }
  }
`)
var footer_menu_heading = data.glstrapi.globalConfig;
var footer_menu_list1   = data.glstrapi.globalConfig.Footer_Menu_List_1;
var footer_menu_list2   = data.glstrapi.globalConfig.Footer_Menu_List_2;
var footer_menu_list3   = data.glstrapi.globalConfig.Footer_Menu_List_3;
return (
    <>
            <Col md={12} className="footer-quick-link-list">
              <div className="footer-menu">
                <div className={`footer-quick-link-block ${OpenMenu1 ? `open-footer-block` : ``}`}>
                  <div  className="footer-quick-link-head">
                    <span><Link to="/services">{footer_menu_heading.Footer_Menu_1}</Link></span>
                    <i onClick={(e) => { setOpenMenu1(false);}}className="icon-minus-footer"></i>
                    <i onClick={(e) => { setOpenMenu1(true);setOpenMenu2(false);setOpenMenu3(false);}} className="icon-plus-footer"></i>
                  </div>
                  <div className="footer-all-links">
                    <ul>
                        {
                            footer_menu_list1 && footer_menu_list1.map((item,index) => {
                              return<>
                              {item.Footer_Menu_Custom_Link?
                                <li><a href={item.Footer_Menu_Custom_Link}>{item.Footer_Menu_Label}</a></li>
                                :
                                <li><GetCta Label={item.Footer_Menu_Label} link={item.Footer_Menu_Link && item.Footer_Menu_Link.Primary_URL}/></li>
                              }                               
                            </>
                              }
                            )
                        }
                    </ul>
                  </div>
                </div>
              </div>
              
            </Col>
            <Col md={12} className="footer-quick-link-list">

            <div className="footer-menu">
                <div className={`footer-quick-link-block ${OpenMenu2 ? `open-footer-block` : ``}`}>
                  <div  className="footer-quick-link-head">
                  <span><Link to="/about">{footer_menu_heading.Footer_Menu_2}</Link></span>
                  <i onClick={(e) => { setOpenMenu2(false);}}className="icon-minus-footer"></i>
                    <i onClick={(e) => { setOpenMenu2(true);setOpenMenu1(false);setOpenMenu3(false);}} className="icon-plus-footer"></i>
                  </div>
                  <div className="footer-all-links">
                  <ul>
                        {
                            footer_menu_list2 && footer_menu_list2.map((item,index) => {
                              return<>

                              {item.Footer_Menu_Custom_Link?
                                <li><a href={item.Footer_Menu_Custom_Link}>{item.Footer_Menu_Label}</a></li>
                                :
                                <li><GetCta Label={item.Footer_Menu_Label} link={item.Footer_Menu_Link && item.Footer_Menu_Link.Primary_URL}/></li>
                              }
                              </>
                            }
                            )
                        }
                    </ul>
                  </div>
                </div>
              </div>
              </Col>
              <Col md={12} className="footer-quick-link-list">

              <div className="footer-menu">
                <div className={`footer-quick-link-block ${OpenMenu3 ? `open-footer-block` : ``}`}>
                  <div  className="footer-quick-link-head">
                  <span><Link to="/branches">{footer_menu_heading.Footer_Menu_3}</Link></span>
                  <i onClick={(e) => { setOpenMenu3(false);}}className="icon-minus-footer"></i>
                  <i onClick={(e) => { setOpenMenu3(true);setOpenMenu1(false);setOpenMenu2(false);}} className="icon-plus-footer"></i>
                  </div>
                  <div className="footer-all-links">
                  <ul>
                        {
                            footer_menu_list3 && footer_menu_list3.map((item,index) => {
                              return<>

                              {item.Footer_Menu_Custom_Link?
                                <li><a href={item.Footer_Menu_Custom_Link}>{item.Footer_Menu_Label}</a></li>
                                :
                                <li><GetCta Label={item.Footer_Menu_Label} link={item.Footer_Menu_Link && item.Footer_Menu_Link.Primary_URL}/></li>
                              }
                              </>
                            }
                            )
                        }
                    </ul>
                  </div>
                </div>
              </div>
              </Col>

    </>
)
}
export default FooterMenu