import React, {useEffect} from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import $ from "jquery";

const Menudata = (props) => {
  useEffect(() => {
    $('.navigation li a').click(function(){
      $(window).scrollTop(0);
    })
    $(".navigation .menu-accordian .submenu-list").each(function(i, el) {
        // Remove white space
        var html = $(el).html().replace(/\s+/, "");
        // Check if element is :empty or length of html is zero
        if ($(el).is(":empty") || html.length == 0){
            $(el).remove();
        } else {
          $(el).parent().addClass('has-submenu');          
        }
    });

    setTimeout(function(){
      $(".card-header.has-submenu .firstleveltrigger").unbind().on("click", function(e){
       var checkactive = $(this).parent().attr('class');
       //console.log('parentttlcassss', $(this).parent().attr('class'))
       if(checkactive.indexOf('active') != -1){
         $(".card-header.has-submenu").removeClass('active');
         $('.submenu-list').stop(true, true).slideUp();
         $('.has-submenu .firstleveltrigger i').removeClass('icon-arrow-up');
         $('.has-submenu .firstleveltrigger i').addClass('icon-arrow-down');
         //console.log(checkactive + " found");
         //e.stopPropagation();
       } else {
         //console.log(checkactive + "not found");
         $(".card-header.has-submenu").removeClass('active');
         $('.submenu-list').slideUp();
         $('.has-submenu .firstleveltrigger i').removeClass('icon-arrow-up');
         $('.has-submenu .firstleveltrigger i').addClass('icon-arrow-down');
         $(this).parent().toggleClass("active");
         $(this).parent().find('.submenu-list').stop(true, true).slideDown();
         $(this).parent().find('.firstleveltrigger i').removeClass('icon-arrow-down');
         $(this).parent().find('.firstleveltrigger i').addClass('icon-arrow-up');
         //e.stopPropagation();
        }
      });

      $(".card-header.has-submenu .secondleveltrigger").unbind().on("click", function(e){
        var checkactiveactivesubmenu = $(this).parent().attr('class');
        if(checkactiveactivesubmenu.indexOf('activesubmenu') != -1){
          $(".card-header.has-submenu .secondlevel-menu-item").removeClass('activesubmenu');
          $('.submenu-list-level2').stop(true, true).slideUp();
          $('.has-submenu .secondleveltrigger i').removeClass('icon-arrow-up');
          $('.has-submenu .secondleveltrigger i').addClass('icon-arrow-down');
          //console.log(checkactiveactivesubmenu + " found");
          //e.stopPropagation();
        } else {
          //console.log(checkactiveactivesubmenu + "not found");
          $(".card-header.has-submenu .secondlevel-menu-item").removeClass('activesubmenu');
          $('.submenu-list-level2').slideUp();
          $('.has-submenu .secondleveltrigger i').removeClass('icon-arrow-up');
          $('.has-submenu .secondleveltrigger i').addClass('icon-arrow-down');
          $(this).parent().toggleClass("activesubmenu");
          $(this).parent().find('.submenu-list-level2').stop(true, true).slideDown();
          $(this).parent().find('.secondleveltrigger i').removeClass('icon-arrow-down');
          $(this).parent().find('.secondleveltrigger i').addClass('icon-arrow-up');
          //e.stopPropagation();
         }
       });

    }, 3000);
 
});

  const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Burger
          New_Tab_Open
          Hide_Menu_In_Sub_Nav
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
            Disable_Parent_URL            
            New_Tab_Open
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL            
          }
        }
      }
    }
  `)
    var menu_data = data.glstrapi.allMenus;
  return (
    <React.Fragment>
    {props.item.Show_In_Burger === true &&
    <li
      className={
        props.item.Show_In_Burger && props.item.isActive
          ? "active card-header card-header-parent"
          : "card-header card-header-parent"
      }
    >
    {props.item.Secondary_URL && !props.item.New_Tab_Open?
    <a href="javascript:;" className="firstleveltrigger parent-link">{props.item.Show_In_Burger === true && props.item.Label}</a>
    :
    props.item.Secondary_URL && props.item.New_Tab_Open?
    <a href={props.item.Secondary_URL} target="_blank" className="firstleveltrigger parent-link">{props.item.Show_In_Burger === true && props.item.Label}</a>
    :
    <a href="javascript:;" className="firstleveltrigger parent-link">{props.item.Show_In_Burger === true && props.item.Label}</a>
    }
    <div className="firstleveltrigger">
    {menu_data.map((menuitem,key) => {
      return<>
      {menuitem.Main_Parent && menuitem.Sub_Parent=== null && menuitem.Main_Parent.Label === props.item.Label &&
        <i className="icon-arrow-down"></i>
      }
      {
        (props.item.Label === "Search" && menuitem.Label === "Search") && <i className="icon-arrow-down"></i>
      }
      </>
    })}
    </div>

    <ul className="submenu-list">
    {menu_data.map((submenu) => {
      //console.log(submenu, "submenu")
        return<>
        {submenu.Main_Parent && submenu.Sub_Parent===null && submenu.Main_Parent.Label == props.item.Label && submenu.Hide_Menu_In_Sub_Nav != true &&
          <li className="card-header card-header-child secondlevel-menu-item">
            {(submenu.Secondary_URL && submenu.New_Tab_Open == false)?
            <Link to={submenu.Secondary_URL+'/'}>{submenu.Label}</Link>
            :
            (submenu.Secondary_URL && submenu.New_Tab_Open == true)?            
            <a target="_blank" href={submenu.Secondary_URL}>{submenu.Label}</a>
            :
            (submenu.Main_Parent.Disable_Parent_URL == true)?
            <Link to={`/${submenu.Primary_URL}/`}>{submenu.Label}</Link>
            :
            <Link to={`/${submenu.Main_Parent.Primary_URL}/${submenu.Primary_URL}/`}>{submenu.Label}</Link>
            }
            <div className="secondleveltrigger">
            {menu_data.map((submenu2) => {
              return<>
                      {submenu2.Main_Parent && submenu2.Sub_Parent && submenu2.Sub_Parent.Label == submenu.Label && 
                              <i className="icon-arrow-down"></i>
                      }
                </>

            })}
            </div>
            <ul className="submenu-list-level2">
            {menu_data.map((submenu1) => {
              return<>
                      {submenu1.Main_Parent && submenu1.Sub_Parent && submenu1.Sub_Parent.Label == submenu.Label &&
                      <li className="menu-accordian">
                        {(submenu1.Secondary_URL && submenu1.New_Tab_Open == false)?
                        <Link to={submenu1.Secondary_URL+'/'}>{submenu1.Label}</Link>
                        :
                        (submenu1.Secondary_URL && submenu1.New_Tab_Open == true)?            
                        <a target="_blank" href={submenu1.Secondary_URL}>{submenu1.Label}</a>
                        :
                        (submenu1.Main_Parent.Disable_Parent_URL == true)?
                        <Link to={`/${submenu1.Sub_Parent.Primary_URL}/${submenu1.Primary_URL}/`}>{submenu1.Label}</Link>
                        :
                        <Link to={`/${submenu1.Main_Parent.Primary_URL}/${submenu1.Sub_Parent.Primary_URL}/${submenu1.Primary_URL}`}>{submenu1.Label}</Link>
                        }
                      </li>
                      }
                </>

            })}
            </ul>
          </li>

        }
        </>
    })}
    </ul>
     
    </li>
    }
  </React.Fragment>
  );
};

export default Menudata;
