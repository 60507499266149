import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import { Link }  from "gatsby"
import $ from "jquery";
import { navigate } from "@reach/router"
import Select from 'react-dropdown-select';
import LocalSiteSearch from './LocalSiteSearch.js'
import SearchInputBox from '../predictive-search/search-areas';
import "./assets/styles/_index.scss"

const SearchOverlay = (props) => {
    // Property status
    const [selectedPropertyTypeValue, setSelectedPropertyTypeValue] = useState('/properties-for-sale/');
    const propertyStatus = [
        { value: '/properties-for-sale/', label: 'Buy' },
        { value: '/properties-to-rent/', label: 'Rent' },
    ];
    // Property status

    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
    const priceminRange = [
      { value: "0", label: "No Min" },
      { value: "100000", label: "£100,000" },
      { value: "200000", label: "£200,000" },
      { value: "300000", label: "£300,000" },
      { value: "400000", label: "£400,000" },
      { value: "500000", label: "£500,000" },
      { value: "600000", label: "£600,000" },
      { value: "700000", label: "£700,000" },
      { value: "800000", label: "£800,000" },
      { value: "900000", label: "£900,000" },
      { value: "1000000", label: "£1,000,000" },
      { value: "1100000", label: "£1,100,000" },
      { value: "1200000", label: "£1,200,000" },
      { value: "1300000", label: "£1,300,000" },
      { value: "1500000", label: "£1,500,000" },
      { value: "2000000", label: "£2,000,000" },
      { value: "2500000", label: "£2,500,000" },
      { value: "3000000", label: "£3,000,000" },
    ];

    const [selectedMinPriceLettingsValue, setSelectedMinPriceLettingsValue] = useState(0);
    const priceminRangeLettings = [
      { value: "0", label: "No Min" },
      { value: "200", label: "£200pcm" },
      { value: "300", label: "£300pcm" },
      { value: "400", label: "£400pcm" },
      { value: "500", label: "£500pcm" },
      { value: "600", label: "£600pcm" },
      { value: "700", label: "£700pcm" },
      { value: "800", label: "£800pcm" },
      { value: "900", label: "£900pcm" },
      { value: "1000", label: "£1,000pcm" },
      { value: "1100", label: "£1,100pcm" },
      { value: "1200", label: "£1,200pcm" },
      { value: "1300", label: "£1,300pcm" },
    ];
  
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
    const pricemaxRange = [
      { value: "0", label: "No Max" },
      { value: "3000000", label: "£3,000,000  +" },
      { value: "2500000", label: "£2,500,000" },
      { value: "2000000", label: "£2,000,000" },
      { value: "1500000", label: "£1,500,000"},
      { value: "1300000", label: "£1,300,000" },
      { value: "1200000", label: "£1,200,000" },
      { value: "1100000", label: "£1,100,000" },
      { value: "1000000", label: "£1,000,000" },
      { value: "900000", label: "£900,000" },
      { value: "800000", label: "£800,000" },
      { value: "700000", label: "£700,000" },
      { value: "600000", label: "£600,000" },
      { value: "500000", label: "£500,000" },
      { value: "400000", label: "£400,000" },
      { value: "300000", label: "£300,000" },
      { value: "200000", label: "£200,000" },
      { value: "100000", label: "£100,000" },
    ];

    const [selectedMaxPriceLettingsValue, setSelectedMaxPriceLettingsValue] = useState(0);
    const pricemaxRangeLettings = [
      { value: "0", label: "No Max" },
      { value: "1300", label: "£1,300pcm +" },
      { value: "1200", label: "£1,200pcm" },
      { value: "1100", label: "£1,100pcm" },
      { value: "1000", label: "£1,000pcm" },
      { value: "900", label: "£900pcm" },
      { value: "800", label: "£800pcm" },
      { value: "700", label: "£700pcm" },
      { value: "600", label: "£600pcm" },
      { value: "500", label: "£500pcm" },
      { value: "400", label: "£400pcm" },
      { value: "300", label: "£300pcm" },
      { value: "200", label: "£200pcm" },
    ];

    const [selectedBedroomsValue, setSelectedBedroomsValue] = useState(0);
    const Bedrooms = [
      { value: "", label: "Any" },
      { value: '1', label: '1 +' },
      { value: '2', label: '2 +' },
      { value: '3', label: '3 +' },
      { value: '4', label: '4 +' },
      { value: '5', label: '5 +' }
    ];

    // Close overlay
    function closeSearchOverlay(e) {
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }
    // Close overlay

    // Sales price filter
    var minPrice = [];
    var maxPrice = pricemaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));
  
    priceminRange.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));
  
    if (minPrice.length > 0) {
    } else {
      minPrice.push(priceminRange[0])
    }
  
    if (maxPrice.length > 0) {
    } else {
      maxPrice.push(pricemaxRange[0])
    }
    // Sales price filter

    // Lettings price filter
    var minPriceLettings = [];
    var maxPriceLettings = pricemaxRangeLettings.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceLettingsValue)));
  
    priceminRangeLettings.map(items => parseInt(items.value) < parseInt(selectedMaxPriceLettingsValue) && minPriceLettings.push(items));
  
    if (minPriceLettings.length > 0) {
    } else {
      minPriceLettings.push(priceminRangeLettings[0])
    }
  
    if (maxPriceLettings.length > 0) {
    } else {
      maxPriceLettings.push(pricemaxRangeLettings[0])
    }
    // Lettings price filter

    // Min price sales
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e[0].value);
    }
    // Min price sales

    // Max Price sales
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e[0].value);
    }
    // Max Price sales

    // Min price lettings
    const onChangeMinPriceLettings = (e) => {
        setSelectedMinPriceLettingsValue(e[0].value);
    }
    // Min price lettings

    // Max Price lettings
    const onChangeMaxPriceLettings = (e) => {
        setSelectedMaxPriceLettingsValue(e[0].value);
    }
    // Max Price lettings

    // Sales & Rent dropdown
    const onChangeSellRent = (e) => {
        setSelectedPropertyTypeValue(e[0].value);
    }
    // Sales & Rent dropdown

    // Bedrooms
    const onChangeBedrooms = (e) => {
        setSelectedBedroomsValue(e[0].value);
    }
    // Bedrooms
    
    // Search results overlay
    let url = "/";
    let priceUrl = ""
    let priceUrlLettings = ""
    let bedroomsUrl = ""

    const searchResultsOverlay = (e) => {
        var searchValue = $(".predictive-search-input .searchVal").val().split(', ').join('-').replace(/ /g,"-").replace(/---/g,"-").replace(/'/g,"").toLowerCase();

        // Area
        if (searchValue !== "") {
            url = searchValue + "/"
        } else {
            url = "essex-and-kent/"
        }
        // Area

        // Sales price
        if ((selectedMinPriceValue !== undefined ) && (selectedMaxPriceValue !== undefined) && (selectedMinPriceValue > 0 ) && (selectedMaxPriceValue > 0)) {
            priceUrl = "between-" + selectedMinPriceValue + "-and-" + selectedMaxPriceValue
          } else if ((selectedMinPriceValue !== undefined) && (selectedMinPriceValue > 0) ) {
            priceUrl = "over-" + selectedMinPriceValue
          } else if ((selectedMaxPriceValue !== undefined) && (selectedMaxPriceValue > 0)) {
            priceUrl = "under-" + selectedMaxPriceValue
        } else { priceUrl = "" }
        // Sales price

        // Lettings price
        if ((selectedMinPriceLettingsValue !== undefined ) && (selectedMaxPriceLettingsValue !== undefined) && (selectedMinPriceLettingsValue > 0 ) && (selectedMaxPriceLettingsValue > 0)) {
            priceUrlLettings = "between-" + selectedMinPriceLettingsValue + "-and-" + selectedMaxPriceLettingsValue
          } else if ((selectedMinPriceLettingsValue !== undefined) && (selectedMinPriceLettingsValue > 0) ) {
            priceUrlLettings = "over-" + selectedMinPriceLettingsValue
          } else if ((selectedMaxPriceLettingsValue !== undefined) && (selectedMaxPriceLettingsValue > 0)) {
            priceUrlLettings = "under-" + selectedMaxPriceLettingsValue
        } else { priceUrlLettings = "" }
        // Lettings price

        // Bedrooms
        if ((selectedBedroomsValue !== undefined) && (selectedBedroomsValue > 0)) {
            bedroomsUrl = selectedBedroomsValue + "-and-more-bedrooms"
        } else { bedroomsUrl = "" }
        // Bedrooms

        var searchFields = (
            (selectedPropertyTypeValue ? selectedPropertyTypeValue : '/properties-for-sale/') + "" + url + "" +
            (bedroomsUrl ? bedroomsUrl + "/" : '') + "" +
            (
                selectedPropertyTypeValue === "/properties-for-sale/" ?
                (priceUrl ? priceUrl + "/" : '') 
                :
                (priceUrlLettings ? priceUrlLettings + "/" : '')
            )
            // (priceUrl ? priceUrl + "/" : '')
        );

        // console.log("$$$$$$", searchFields)
        navigate(searchFields);
        setTimeout(function(){ 
            $("body.modal-open").removeClass("modal-open")
            $(".search-overlay").removeClass("active-search")
            $(".search-modal").remove();    
        }, 1000);
    }
    // Search results overlay

    return(
        <>
            <div className="search-overlay">
                <a href="javascript:void(0)" onClick={closeSearchOverlay}><i className="icon-close"></i></a>
                <Container className="property-search-overlay_vertical_align">
                    <Row className="d-flex justify-content-center">
                        <Col lg={6}>
                            <Tabs defaultActiveKey="propertysearch" id="uncontrolled-tab-example" className="search-tab">
                                <Tab eventKey="sitesearch" className="search-overlay-input" title="Site Search">
                                    {/* <Form.Control type="text" className="" placeholder="Search the website" /> */}
                                    <LocalSiteSearch />
                                </Tab>
                                <Tab eventKey="propertysearch" title="Property Search">
                                    <div className="input-group mb-3 property-search-overlay">
                                        <div className="property-search-width search-overlay-input predictive-search-input property-search_overlay property_search_overlay">
                                            <SearchInputBox />
                                            {/* <Form.Control type="text" className="" placeholder="Find a property by area, street or postcode" /> */}
                                        </div>
                                    </div>

                                    <div className="box-wrapper predictive-search-wrapper d-lg-flex align-items-center justify-content-between">
                                        <div className="select-boxes buy-select">
                                            <Select placeholder="Buy" searchable="false"
                                                options={propertyStatus}
                                                defaultValue={propertyStatus.find(obj => obj.value === selectedPropertyTypeValue)}
                                                onChange={e => onChangeSellRent(e)}
                                            />
                                        </div>

                                        {
                                            selectedPropertyTypeValue === "/properties-for-sale/" ?
                                            <>
                                                <div className="select-boxes min-price-select">
                                                    <Select placeholder="Min Price" searchable={false}
                                                        options={parseInt(selectedMaxPriceValue) === 0 ? priceminRange : minPrice}
                                                        defaultValue={priceminRange.find(obj => obj.value === selectedMinPriceValue)}
                                                        // onChange={e => { onChangeMinPrice(e); setMinPriceVal(e[0].value) }}
                                                        onChange={e => { onChangeMinPrice(e) }}
                                                    />
                                                </div>

                                                <div className="select-boxes max-price-select">
                                                    <Select placeholder="Max Price" searchable={false}
                                                        options={parseInt(selectedMinPriceValue) === 0 ? pricemaxRange : maxPrice}
                                                        defaultValue={pricemaxRange.find(obj => obj.value === selectedMaxPriceValue)}
                                                        // onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e[0].value) }}
                                                        onChange={e => { onChangeMaxPrice(e) }}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="select-boxes min-price-select">
                                                    <Select placeholder="Min Price" searchable={false}
                                                        options={parseInt(selectedMaxPriceLettingsValue) === 0 ? priceminRangeLettings : minPriceLettings}
                                                        defaultValue={priceminRangeLettings.find(obj => obj.value === selectedMinPriceLettingsValue)}
                                                        onChange={e => { onChangeMinPriceLettings(e) }}
                                                    />
                                                </div>

                                                <div className="select-boxes max-price-select">
                                                    <Select placeholder="Max Price" searchable={false}
                                                        options={parseInt(selectedMinPriceLettingsValue) === 0 ? pricemaxRangeLettings : maxPriceLettings}
                                                        defaultValue={pricemaxRangeLettings.find(obj => obj.value === selectedMaxPriceLettingsValue)}
                                                        onChange={e => { onChangeMaxPriceLettings(e) }}
                                                    />
                                                </div>
                                            </>
                                        }

                                        <div className="select-boxes bedroom-select">
                                            <Select placeholder="Bedrooms" searchable={false}
                                                options={Bedrooms}
                                                defaultValue={Bedrooms.find(obj => obj.value === selectedBedroomsValue)}
                                                // onChange={e => setBedVal(e[0].value)}
                                                onChange={e => { onChangeBedrooms(e) }}
                                            />
                                        </div>
                                    </div>

                                    <button className="btn predictive-search-btn" onClick={e => searchResultsOverlay(e)}>Search</button>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SearchOverlay