import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import $ from "jquery"


const Topbar = () => {

  const [scroll, setScroll] = useState(false);
  const [visible, setVisible] = useState("show");

  useEffect(() => {  

    window.addEventListener("scroll", () => {
        if(window.scrollY > 20) {
            setVisible("hidden");
            $('.main-home-page .header').removeClass('with_balgores_popup');
            $('.main-home-page .banner').removeClass('with_balgores_popup');
        }
    });

  }, []);

    useEffect(() => {
    setTimeout(function(){
        $('.triggerclose').click(function(){
            $('.secureclose').click();
        });
        if ($('.topbar .CookieConsent').length > 0) { 
            $('.main-home-page .header').addClass('with_balgores_popup');
            $('.main-home-page .banner').addClass('with_balgores_popup');
        }    
        }, 500);
        
    }, []);
 
     const CloseTopbar = () => {
        if (typeof window !== 'undefined') {
            setVisible("hidden");
            $('.main-home-page .header').removeClass('with_balgores_popup');
            $('.main-home-page .banner').removeClass('with_balgores_popup');
        }
     }
    return (
        <React.Fragment>
            <div className="topbar">
                <CookieConsent
                    location="top"
                    buttonText="Accept"
                    cookieName="BalgoresSecure"
                    acceptOnScroll={true}
                    buttonClasses="secureclose"
                    acceptOnScrollPercentage={2}
                    onAccept={()=> CloseTopbar()}
                    visible={visible}
                >
                    <div className="topbar-block">

                        <p>
                            <i className="icon-home-dark"></i><strong>Move Home with Certainty.</strong> If you are interested in securing your property sale with a financial commitment from your buyer on offer, <a className="triggerclose" href="https://balgores.gazeal.co.uk/the-safest-agent-in-your-area/" target="_blank">click here</a> to get started.
                        </p>
                        <i className="icon-close-dark"></i>
                    </div>
                </CookieConsent>

                    {/* <p>
                    <i className="icon-home-dark"></i><strong>Move Home with Certainty.</strong> If you are interested in securing your property sale with a financial commitment from your buyer on offer, <a href="#" className="">click here</a> to get started
                    </p>
                    </div>
                    <i className="icon-close-dark"></i> */}
            </div>
        </React.Fragment>
    )
}
export default Topbar;